
<template>

  <div>

    <div class="container mb-4">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="formularioGarantia()" :disabled="solo_lectura"> <i class='fa fa-plus mr-sm-1'></i>Agregar garantía</button>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-12" v-if="!garantias_brokers.length">
        <div class="alert alert-warning text-center w-100" role="alert">
          No se encontraron garantías vinculadas al broker seleccionado, puede agregarlas dando clic en el botón "Agregar garantia".
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4" v-for="garantia in garantias_brokers">
        <div class="card m-1" :class="garantia.estatus.toLowerCase() === 'inactivo' ? 'bg-light border-secondary' :''">
          <div class="card-body p-3">
            <div class="card-text clearfix mb-3">
              <span class="badge badge-secondary py-1 text-uppercase text-white float-left">{{ garantia.garantia.tipo_garantia_juridica }}</span>
              <small class="text-muted float-right">
                Id Garantia: {{ garantia.garantia_id.substring(0,8)}} <br>
                Estatus: <span class="text-uppercase">{{ garantia.estatus }}</span>
              </small>

            </div>

            <i class="fa fa-home mr-sm-2" :class="garantia.estatus.toLowerCase() === 'inactivo' ? 'text-secondary' : 'text-primary'"></i>
            <p class="card-text d-inline">
              {{ garantia.garantia.calle+' #'+garantia.garantia.numero_exterior+' '+(!garantia.garantia.numero_interior ? ' ' : garantia.garantia.numero_interior)
            +', '+garantia.garantia.colonia+', C.P. '+garantia.garantia.cp+', ' +garantia.garantia.municipio+', '+
            garantia.garantia.estado+', '+garantia.garantia.pais+'.'
              }}</p>
            <p class="card-text text-right mt-2">
              <button type="button" class="btn btn-sm btn-primary py-0 mr-2" @click="editar(garantia)" :disabled="solo_lectura"><i class="fa fa-edit"></i> Editar</button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Modal v-if="formGarantia" :options="{width: '60vw',type:'info'}">
      <div class="title">{{ !garantias_juridicas.id ? 'Agregar Garantia' : 'Editar Garantia' }}</div>
      <div class="body">

        <div class="row justify-content-center">

          <div class="alert alert-warning" role="alert" v-if="solo_lectura">
            <i class="fa fa-exclamation-triangle mr-sm-1"></i> <b>Información de solo lecura:</b>  El estatus de la garantía o broker tiene acceso reestringido a la edición de información de la garantía exceptuando el cambio de estatus.
          </div>

          <template v-if="!garantia_broker.id">
            <div class="col-12">
              <div class="alert alert-primary" role="alert">
                <i class="fa fa-info-circle mr-sm-1"></i> <b>Instrucciones:</b> Seleccione el tipo de busqueda para obtener las garantías de fideicomisos disponibles para asignación e ingrese los datos correspondientes para la busqueda, después seleccione la garantia para el broker y complete los datos faltantes.
              </div>
            </div>

            <div class="col-sm-4 form-group">
              <label class="form-label">Tipo de busqueda</label>
              <select class="form-control" name="tipo_busqueda" id="tipo_busqueda"  v-model="busqueda.tipo_busqueda" :disabled="on_search" onchange="cambiar_tipo_busqueda()">
                <option class="text-capitalize" :value="tipo" v-for="tipo in tipos_busqueda">{{tipo}}</option>
              </select>
            </div>

            <div class="col-sm-8 form-group" v-if="busqueda.tipo_busqueda === 'persona'">
              <label class="form-label">Buscar y seleccionar cliente</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Buscar cliente..." v-model="busqueda_cliente" @input="buscar_cliente">
              </div>

              <div class="list-group text-left" v-if="on_search">
                <a href="#" class="list-group-item list-group-item-action" @click="seleccionar_cliente(cliente)" v-for="cliente in clientes">{{cliente.nombre_completo}}</a>
              </div>
            </div>

            <div class="col-sm-8 form-group" v-if="busqueda.tipo_busqueda === 'fideicomiso'">
              <label class="form-label">Buscar y seleccionar fideicomiso</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Ingresar folio de fideicomiso..." v-model="busqueda.fideicomiso" @input="buscar_fideicomiso()">
              </div>

              <div class="list-group text-left" v-if="on_search">
                <a href="#" class="list-group-item list-group-item-action" @click="seleccionar_fideicomiso(fideicomiso)" v-for="fideicomiso in fideicomisos">{{fideicomiso.id}}</a>
              </div>
            </div>
          </template>


          <div class="col-12 form-group">
            <div v-if="garantia_broker.id">
              <b>Garantia:</b> <br>
              <span class="font-italic">{{direccion_garantia_editar}}</span></div>
            <template v-else>
              <label for="garantia_id" class="form-label">Seleccionar Garantía</label>
              <select class="form-control" name="garantia_id" id="garantia_id"  v-model="garantia_broker.garantia_id" :disabled="!garantias.length || on_search">
                <option :value="null" v-if="!garantias.length" selected>No hay garantias disponibles para agregar.</option>
                <option v-if="!garantias.length" :value="index" v-for="(value, index) in garantias_select">{{index}}.</option>
                <optgroup :label="'Garantias fideicomiso: '+index" v-for="(value, index) in garantias_select">
                  <option :value="garantia.garantia_id" v-for="garantia in value">
                    {{ garantia.garantia.calle+' #'+garantia.garantia.numero_exterior+' '+(!garantia.garantia.numero_exterior ? ' ' : garantia.garantia.numero_exterior)+', '+garantia.garantia.colonia+', C.P. '+garantia.garantia.cp+', ' +garantia.garantia.municipio+', '+garantia.garantia.estado+', '+garantia.garantia.pais+'.'}}
                  </option>
                </optgroup>
              </select>
            </template>

          </div>

          <template v-if="garantia_broker.garantia_id">

            <div class="col-sm-6 form-group">
              <label for="identificador_externo_garantia_broker" class="form-label">Identificador externo <small class="text-muted">(opcional)</small></label>
              <div class="text-bold" v-if="solo_lectura">
                <i v-if="!garantia_broker.identificador_externo" class="text-danger">Valor no ingresado</i>
                <b v-else>{{garantia_broker.identificador_externo}}</b>
              </div>
              <input v-else v-model="garantia_broker.identificador_externo" type="text" name="identificador_externo" id="identificador_externo_garantia_broker" class="form-control">
            </div>

            <div class="col-sm-6 form-group">
              <label for="jerarquia" class="form-label">Jerarquía</label>
              <div v-if="solo_lectura" class="text-bold"><b>{{garantia_broker.jerarquia}}</b></div>
              <select v-else class="form-control" name="jerarquia" id="jerarquia"  v-model="garantia_broker.jerarquia">
                <option :value="jerarquia.valor" v-for="jerarquia in jerarquias.opciones">{{jerarquia.nombre}}</option>
              </select>
            </div>

            <div class="form-group col-sm-6">
              <label for="estatus_garantia_broker" class="form-label">Estatus</label>
              <select class="form-control" name="estatus_garantia_broker" id="estatus_garantia_broker"  v-model="garantia_broker.estatus" >
                <option value="activo">Activo</option>
                <option value="inactivo">Inactivo</option>
              </select>
            </div>
          </template>


        </div>


      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar" :disabled="on_search"><i class="fa fa-check-square mr-sm-1"></i>{{ !garantia_broker.id ? 'Guardar' : 'Guardar Cambios' }}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
    </div>

</template>

<script>
import Modal from "@/components/Modal.vue";
import utilidades from "../../../../helpers/utilidades";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
import garantiasApi from "@/apps/garantias_app/api/personas/creditos/garantias.api";
import garantiasFideicomisoApi from "@/apps/garantias_app/api/personas/fideicomisos/garantias/garantiasFideicomiso.api";
import personasApi from "@/apps/garantias_app/api/personas/personas.api";
import brokersApi from "@/apps/garantias_app/api/brokers/brokers.api";
import fideicomisosApi from "@/apps/garantias_app/api/personas/fideicomisos/fideicomisos.api";
import axios from "axios";


export default {
  name: "BrokersGarantias"
  , props: {
    broker_id: {
      type: String
      ,required: true
      ,default() {
        return null
      }
    }
  ,prop_solo_lectura: {
    type: Boolean
    ,required: false
    ,default() {
      return false
    }
  }
  }
  , components: { Modal}
  , data() {
    return {
      garantias: [],
      fideicomisos: [],
      garantias_brokers: [],
      garantias_juridicas: {nombre: 'Tipo', opciones: []}
      ,formGarantia: false
      ,openModalQuitarGarantia: false
      ,garantia_select: null
      ,garantia_broker: {
        id: null,
        broker_id: null,
        garantia_id: null,
        estatus: null,
        jerarquia: null,
        identificador_externo: null,
        garantia: {}
      }
      ,tipos_busqueda: ['persona','fideicomiso']
      ,on_search:false
      ,busqueda_cliente: ''
      ,busqueda:{
        tipo_busqueda: 'persona',
        fideicomiso: null,
        persona: null,
      }
      ,clientes: []
      ,jerarquias: {nombre: 'Jerarquía broker', opciones: []}
      ,cancelTokenSource: null,
    }
  }
  , async mounted() {

    this.garantia_broker.broker_id = this.broker_id
    await this.garantiasBroker()

  }
  , computed: {
    utilidades() {
      return utilidades
    }
    ,solo_lectura(){
      return this.garantia_broker.estatus && this.garantia_broker.estatus.toLowerCase() === 'inactivo' || this.prop_solo_lectura
    },
    garantias_select() {
      let garantias = this.garantias.filter((garantia) => {
        return !this.garantias_brokers.some(garantia_broker => garantia_broker.garantia_id === garantia.id)
      })

      if (garantias.length){
        garantias = garantias.reduce((garantias_fideicomisos, garantia) => {
          if(!garantias_fideicomisos[garantia.fideicomiso_id]){
            garantias_fideicomisos[garantia.fideicomiso_id] = []
          }
          garantias_fideicomisos[garantia.fideicomiso_id].push(garantia)
          return garantias_fideicomisos;
        }, {})

        }

      return garantias
    }
    ,direccion_garantia_editar() {
      return this.garantia_broker.garantia.calle + ' #' + this.garantia_broker.garantia.numero_exterior
          + ' ' + (!this.garantia_broker.garantia.numero_interior ? ' ' : this.garantia_broker.garantia.numero_interior)
          + ', ' + this.garantia_broker.garantia.colonia + ', C.P. ' + this.garantia_broker.garantia.cp
          + ', ' + this.garantia_broker.garantia.municipio + ', ' + this.garantia_broker.garantia.estado
          + ', ' + this.garantia_broker.garantia.pais + '.'

    }
  }
  , methods: {

    reset_busqueda(){
      this.busqueda_cliente =  ''
      this.on_search = false
      this.busqueda = {
        tipo_busqueda: 'persona',
        fideicomiso: null,
        persona: null,
      }
    }
    ,cambiar_tipo_busqueda(){
      if (this.busqueda.tipo_busqueda === 'fideicomiso') {
        this.on_search = false
        this.busqueda_cliente = ''
        this.busqueda.persona = null
      }else{
        this.busqueda.fideicomiso = null
      }
    }
    ,async cargarCatalogoJerarquias(){
      const dataSource = await catalogosApi.buscar('jerarquias_brokers')
      this.jerarquias = dataSource.data

    }
    ,async garantiasBroker() {

      const opciones = {
        filters: [{
          relacion: "and",
          columna: "broker_id",
          operador: "equal_to",
          valor1: this.broker_id,
          valor2: null
        }],
        relations: ['garantia']
      }

      const dataSource = await brokersApi.index_garantias(false,opciones)
      this.garantias_brokers = dataSource.data
    }

    ,cancelarRequestBusqueda () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Se cancela busqueda anterior, nueva busqueda activa');
      }
    }

    ,async buscar_cliente(){

      this.garantias = []
      this.on_search = (this.busqueda_cliente.length > 2)

      if (this.on_search){

        this.reset_garantia_broker()
        this.garantia_broker.garantia_id = null

        //Cancela request anterior de busqueda
        this.cancelarRequestBusqueda()

        //Asigna source de token de cancelación de request
        this.cancelTokenSource = axios.CancelToken.source();


        const filtros = { limit: 5, search: this.busqueda_cliente}
        const dataSource = await personasApi.index(false, filtros, this.cancelTokenSource.token)


        this.clientes = dataSource.data
      }

    },
    async buscar_fideicomiso(){

      this.garantias = []
      this.on_search = (this.busqueda.fideicomiso.length > 2)

      if (this.on_search){

        this.reset_garantia_broker()
        this.garantia_broker.garantia_id = null

        //Cancela request anterior de busqueda
        this.cancelarRequestBusqueda()

        //Asigna source de token de cancelación de request
        this.cancelTokenSource = axios.CancelToken.source();

        const options = { limit: 5, search: this.busqueda.fideicomiso}
        const dataSource = await fideicomisosApi.index(options, false, this.cancelTokenSource.token)
        this.fideicomisos = dataSource.data
      }

    }
    ,seleccionar_cliente(cliente){
      this.on_search = false
      this.busqueda_cliente = cliente.nombre_completo
      this.busqueda.persona = cliente.id
      this.obtener_garantias_asignables()
    }
    ,seleccionar_fideicomiso(fideicomiso){
      this.garantia_broker.garantia_id = null
      this.on_search = false
      this.busqueda.fideicomiso = fideicomiso.id
      this.obtener_garantias_asignables()
    },

    async obtener_garantias_asignables() {

      const dataSource = await brokersApi.obtener_garantias_asignables(this.busqueda, false)
      this.garantias = dataSource.data

      if (!this.garantias.length) {
        const mensaje = this.busqueda.persona ? 'No se encontrarón garantías disponibles para asignación de la persona seleccionada' :
            'No se encontrarón garantías disponibles para asignación del fideicomiso ingresado'
        this.$helper.showMessage('Garantías', mensaje, 'warn', 'alert')
      }

    },

    async formularioGarantia() {

      /**
      if (!this.garantias.length) {
        await this.garantias()
      }

      if (!this.garantias_juridicas.opciones.length) {
        const dataSource = await catalogosApi.buscar('garantias_juridicas')
        this.garantias_juridicas = dataSource.data
      }**/

      if (!this.jerarquias.opciones.length)
        await this.cargarCatalogoJerarquias()

      this.formGarantia = true
    },


    editar(garantia) {
      this.garantia_broker = {...garantia}
      this.formularioGarantia()
    }
    ,reset_garantia_broker(){
      this.garantia_broker =  {
        id: null,
        broker_id: this.broker_id,
        garantia_id: null,
        estatus: null,
        jerarquia: null,
        identificador_externo: null,
        garantia: {}
      }
    }
    , cancelar_seleccion() {

      this.reset_busqueda()

      this.formGarantia = false;
      this.openModalQuitarGarantia = false;

      this.reset_garantia_broker()
    }
    , pre_eliminar(garantia) {
      this.garantia_broker = {...garantia}
      this.openModalQuitarGarantia = true
    }

    , async eliminar() {

      if (this.garantia_broker.hasOwnProperty('id') && this.garantia_broker.id !== null)
        await garantiasFideicomisoApi.eliminar(this.fideicomiso_id, this.garantia_broker.id)

      this.$helper.showMessage('Quitar garantía', 'Se quitó relación de garantía con fideicomiso exitosamente.', 'success', 'alert')

      this.cancelar_seleccion()
      await this.garantiasBroker()
    }

    , async guardar() {

      let isValid = await this.$validator({
        garantia_id: !this.garantia_broker.garantia_id ?  'required' : '',
        jerarquia: !this.garantia_broker.jerarquia ? 'required' : 'max:150',
        identificador_externo_garantia_broker:  'max:150',
        estatus_garantia_broker:  'required',
      })


      if (isValid) {
        try {

          const {garantia,...payload_garantia} = this.garantia_broker
          let payload = payload_garantia

          if (!this.garantia_broker.identificador_externo){
            const {identificador_externo,...data_gantia} = payload
            payload = data_gantia
          }

          if (this.garantia_broker.id !== null) {
            await brokersApi.editar_garantia(this.garantia_broker.id,payload)
            this.$helper.showMessage('Editar garantía de broker', 'Información guardada exitosamente.', 'success', 'alert')

          } else {
            await brokersApi.crear_garantia(payload)
            this.$helper.showMessage('Agregar garantía a broker', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await this.garantiasBroker()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.cliente_id ? 'Guardar' : 'Editar') + ' garantía', 'Ocurrio un error al guardar datos de garantía, favor de reportarlo al administador.', 'error', 'alert')
        }
      }

    }
  }
  ,watch:{
    "garantia_broker.garantia_id":{
      handler(newValue, oldValue) {
        if (typeof oldValue == 'string' && newValue !== oldValue) {
          this.reset_garantia_broker()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
