import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  index(paginado = true, options = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/brokers`, options, paginado));
  }

  ,obtener(broker) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/brokers/${broker}`);
  },

  crear(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/brokers`, payload)
  },

  editar(broker, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/brokers/${broker}`, payload)
  },

  index_garantias(paginado = true, options = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/brokers/garantias`, options, paginado));
  }

  ,obtener_garantia(garantia) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/brokers/garantias/${garantia}`);
  },

  crear_garantia(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/brokers/garantias`, payload)
  },

  editar_garantia(garantia, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/brokers/garantias/${garantia}`, payload)
  },

  obtener_garantias_asignables(payload, paginado = true, options = {}){
    return garantiasAdminApi.api.post(utils.url_options(`${garantiasAdminApi.baseURL}/brokers/garantias/buscar`, options, paginado), payload)
  }

}