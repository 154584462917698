import Vue from "vue";
import axiosV2 from "@/plugins/axiosV2";
import axios from "axios";
import vue from "vue";



/**
 * baseUrl de api de admin de aplicaciones
 * @type {string}
 */
const baseURL = process.env.VUE_APP_BPB_APPS_API_GARANTIAS;



/**
 * Interceptor
    return response;
 */

const http = axiosV2(baseURL);

http.interceptors.response.use(response => {
  return response;
}, async function (error){

  if (axios.isCancel(error)) {
    vue.prototype.$log.info('Request cancelada',error);
    return Promise.reject(error);
  }


  let response = error.response;

  if ( response.config.url.includes(baseURL)){
    let message = !response.data.message ? `Error inesperado: ${response.data.code}` : response.data.message;

    if (response.status === 400 && response.data.error) {
      message += '<br>'+Object.values(response.data.error).join("<br>")
    }

    Vue.prototype.$helper.showMessage('Error',message,'error','alert');
  }

  return Promise.reject(error);
})


export default {
  api: http,
  baseURL
}

