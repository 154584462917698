import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";
import axios, {CancelTokenSource} from "axios";
import Vue from "vue";

export default {

  campos_dinamicos(){
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/campos`);
  }

  ,index(paginado = true,filtros = {}, cancel_token =  null ) {

    if (!cancel_token)
      return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas`, filtros, paginado));

    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas`, filtros, paginado), {cancelToken: cancel_token});
  }

  ,obtener(persona) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}`);
  },

  crear(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas`, payload)
  },

  editar(persona, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/${persona}`, payload)
  },

  eliminar(persona) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/${persona}`)
  },

  logs(persona,options) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/${persona}/logs`, options));
  }

  ,alta_masiva_leer_archivo(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/alta/archivo`, payload);
  }

  ,alta_masiva(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/alta`, payload);
  }
}