import utils from "@/helpers/utilidades";
import garantiasAdminApi from "../../../garantias_admin.api";


export default {

  index(paginado = true,filtros = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/fideicomisos/garantias`, filtros, paginado));
  },

  crear(fideicomiso, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/garantias`, payload)
  }

  ,obtener(fideicomiso,fideicomiso_garantia_id) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/garantias/${fideicomiso_garantia_id}`);
  },

  editar(fideicomiso,fideicomiso_garantia_id, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/garantias/${fideicomiso_garantia_id}`, payload)
  },

  eliminar(fideicomiso,fideicomiso_garantia_id) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/garantias/${fideicomiso_garantia_id}`)
  },

}