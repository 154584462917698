import garantiasAdminApi from "../../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  campos_dinamicos(){
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/fideicomisos/campos`);
  }

  ,formulario(credito_id, payload){
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/fideicomisos/formulario/${credito_id}`, payload);
  }

  ,index(options = {}, paginado = false, cancel_token =  null) {

    if (!cancel_token)
      return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/fideicomisos`, options, paginado));

    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/fideicomisos`, options, paginado), {cancelToken: cancel_token});
  }

  ,obtener(persona, fideicomiso) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}/fideicomisos/${fideicomiso}`);
  },

  crear(persona, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/${persona}/fideicomisos`, payload)
  },

  editar(persona,fideicomiso, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/${persona}/fideicomisos/${fideicomiso}`, payload)
  },

  eliminar(persona,fideicomiso) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/${persona}/fideicomisos/${fideicomiso}`)
  },

  descargar_estado_cuenta(fideicomiso) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/estado_cuenta`, {responseType: 'blob'})
  },

}