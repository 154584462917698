import garantiasAdminApi from "../../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  campos_dinamicos(persona){
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/garantias/campos`);
  }

  ,index(persona, credito,  options = {}, paginado = false) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}/garantias`,options,paginado));
  }

  ,obtener(persona, credito, garantia) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}/garantias/${garantia}`);
  },

  crear(persona, credito, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}/garantias`, payload)
  },

  editar(persona,credito, garantia, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}/garantias/${garantia}`, payload)
  },

  eliminar(persona,credito, garantia) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/${persona}/creditos/${credito}/garantias/${garantia}`)
  },

  /**
   * Campos de formulario de garantia con valores de origen externo
   * @param payload
   * @returns {*}
   */
  formulario(payload){
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/null/creditos/garantias/formulario`, payload);
  }

}