import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  estados() {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/catalogos/estados`)
  }

  ,munincipios(estado) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/catalogos/estados/${estado}/municipios`)
  }

  ,index(paginado = true,filtros = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/catalogos`, filtros, paginado))
  }

  ,buscar(codigo_catalogo) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/catalogos/${codigo_catalogo}`)
  }

  ,crear(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/catalogos`, payload)
  }

  ,editar(catalogo,payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/catalogos/${catalogo}`, payload)
  }

  ,eliminar(catalogo) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/catalogos/${catalogo}`)
  },

 /* OPCIONES CATÁLOGOS */

  crear_opcion(catalogo, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/catalogos/${catalogo}/opciones`, payload)
  }

  ,editar_opcion(catalogo, opcion, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/catalogos/${catalogo}/opciones/${opcion}`, payload)
  }

  ,eliminar_opcion(catalogo, opcion) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/catalogos/${catalogo}/opciones/${opcion}`)
  }


}